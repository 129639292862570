.transitioncontainer {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
}

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.answerOptions {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* QuestionCount Component */
.questionCount {
  xpadding: 0 2.5rem;
  font-size: 14px;
}

/* Question Component */
.question {
  margin: 0;
  padding: 0.5rem 2.5rem 1.5rem 2.5rem;
}

/* AnswerOption Component */
.answerOption {
  border-top: 1px solid #eee;
}

.answerOption:hover {
  background-color: #ecf8ec;
}

.radioCustomButton {
  position: absolute;
  width: auto;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1.5rem 2.5rem 1.5rem 5rem;
  font-size: 16px;
  line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 20px;
  left: 38px;
  width: 28px;
  height: 28px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: '';
  background: #8bc53f url(../icons/CheckIcon.svg) no-repeat;
  background-size: 27px;
  border-color: #8bc53f;
}

/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}